<template>
  <div class="message-batch">
    <Header>
      <router-link to="/message/rule">
        <div class="rule">计费规则</div>
      </router-link>
    </Header>
    <div class="content total">
      <div>最新发送短信</div>
      <div>{{lastSms.sendNumber || 0}}条</div>
    </div>

    <div class="content msg">
      <div class="title">短信内容</div>
      <div class="msg-content">{{lastSms.smsContent}}</div>
      <div class="button-container">
        <router-link :to="{name: 'MessageEdit', params: {...lastSms, smsContent: ''}}">
          <Button class="button" size="mini-plain">短信编辑</Button>
        </router-link>
        <router-link :to="{name: 'MessageEdit', params: lastSms}">
          <Button class="button">转发</Button>
        </router-link>
      </div>
    </div>

    <div class="content statistics">
      <div class="title">信息统计</div>
      <div class="item">
        <div class="label">累计发送/条</div>
        <router-link to="/message/list?status=0">
          <div class="num">{{sendSummary.allSendedNumber}}</div>
        </router-link>
      </div>
      <div class="item">
        <div class="label">累积费用/条</div>
        <div class="num">{{sendSummary.allSendedAmount}}</div>
      </div>
      <div class="item">
        <div class="label">本月发送/条</div>
        <router-link to="/message/list?status=0">
          <div class="num">{{sendSummary.monthSendedNumber}}</div>
        </router-link>
      </div>
      <div class="item">
        <div class="label">本月费用/条</div>
        <div class="num">{{sendSummary.monthSendedAmount}}</div>
      </div>
      <div class="item">
        <div class="label">驳回/条</div>
        <router-link to="/message/list?status=-1">
          <div class="num">{{sendSummary.rejectNumber}}</div>
        </router-link>
      </div>
      <div class="item">
        <div class="label">待审核/条</div>
        <router-link to="/message/list?status=3">
          <div class="num">{{sendSummary.sendingNumber}}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchLastSmsApp, fetchSendSummary } from '@/api/message';
import Header from '@/components/header';
import Button from '@/components/common/button/CustomButton';
export default {
  components: {
    Header,
    Button,
  },
  data() {
    return {
      sendSummary: {},
      lastSms: {},
      msgContent: '',
    };
  },
  mounted() {
    this.getSendSummary();
    this.getLastSms();
  },
  methods: {
    /**
     * 查询发送统计
     */
    async getSendSummary() {
      const res = await fetchSendSummary();
      this.sendSummary = res.data;
    },
    /**
     * 查询最后一条发送情况
     */
    async getLastSms() {
      const res = await fetchLastSmsApp();
      this.lastSms = res.data;
    }
  }
}
</script>

<style lang="scss" scoped>
.message-batch {
  text-align: left;
  min-height: 100vh;
  background-color: #FAFBFC;
  .rule {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 24px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #FFFFFF;
  }
  .content {
    margin-top: 12px;
    width: 100%;
    background-color: #fff;
    padding: 10px 24px;
    box-sizing: border-box;
    &.total {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #91939A;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &.msg {
      .title {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #91939A;
      }
      .msg-content {
        font-size: 13px;
        font-weight: 400;
        line-height: 19px;
        color: #2C2C2C;
        margin-top: 12px;
      }
      .button-container {
        margin-top: 17px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .button {
          margin-left: 10px;
          width: 60px;
          padding: 0;
        }
      }
    }
    &.statistics {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .title {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #616266;
        padding-bottom: 6px;
        width: 100%;
      }
      .item {
        margin-top: 6px;
        width: 50%;
        display: flex;
        align-items: center;
        .label {
          width: 77px;
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: #91939A;
        }
        .num {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: #CD001F;
          margin-left: 6px;
        }
      }
    }
  }
}
</style>